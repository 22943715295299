<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <ServiceRadioFormPart @result="onResult" @change="onChange" />

      <h2 class="form-modal__part-title">Wanneer is jouw woning gebouwd?</h2>

      <PeriodOfConstructionFormPart
        ref="periodOfConstruction"
        @result="onResult"
        @change="onChange"
      />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import ServiceRadioFormPart from 'chimera/heatPump/components/form/parts/service/ServiceRadioFormPart.vue'
import PeriodOfConstructionFormPart from 'chimera/heatPump/components/form/parts/periodOfConstruction/PeriodOfConstructionFormPart.vue'
import TypeOfInsulationPage from '~/pages/offertes-aanvragen/type-isolatie'
import TypeOfHeatingPage from '~/pages/offertes-aanvragen/type-verwarming'

export default {
  name: 'ServiceTypeFormStep',

  components: {
    ServiceRadioFormPart,
    FormErrorMessages,
    PeriodOfConstructionFormPart,
  },

  extends: AbstractFormStep,

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      let route

      const value = this.$store.getters['lead/getData'](
        'period-of-construction',
      )[0].value
      const skipStep =
        value === this.$t('field.period-of-construction.options.after-1992')
      if (skipStep) {
        // Clear insulation data, in case the user went back on that step.
        // Since we skip it, we don’t want to keep the data.
        this.$store.dispatch('lead/rm', 'type-of-insulation')
        route = TypeOfHeatingPage
      } else {
        route = TypeOfInsulationPage
      }

      this.routeTo(route)
    },
  },
}
</script>
